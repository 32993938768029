import { template as template_ea0415da5b17435e9ae3d87596030b12 } from "@ember/template-compiler";
const FKControlMenuContainer = template_ea0415da5b17435e9ae3d87596030b12(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
