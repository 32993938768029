import { template as template_ea66e0787f3c4a5484a20fbbcabcf498 } from "@ember/template-compiler";
const SidebarSectionMessage = template_ea66e0787f3c4a5484a20fbbcabcf498(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
